import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get("/users?accountType=1&page=" + page);
  }
  get(id) {
    return axios.get(`/users/${id}`);
  }
  create(data) {
    return axios.post("/providers", data);
  }
  update(id, data) {
    // return axios.put(`/users/${id}`, data);
    return axios.put(`/users`, data);
  }
  delete(id) {
    return axios.delete(`/users/${id}`);
  }
  changePassword(id) {
    return axios.get(`/changePassword/${id}`);
  }
  getUserCount() {
    return axios.get("/users/count?accountType=1");
  }

  getReviewsCount() {
    return axios.get("/reviews/count?provider_id=1");
  }
  reviewData(providerId,page) {
    return axios.get(`/reviews?providerId=${providerId}&page=${page}`);
  }

  deleteReview(id) {
    return axios.delete(`/reviews?id=${id}`);
  }
  
}
export default new DataService();